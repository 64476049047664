<script setup lang="ts">
import { email, required } from '@vee-validate/rules';
import { Form } from 'vee-validate';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { subscribe } from '@/api/contacts';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import Container from '@/components/base/layout/Container.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import ToastNotification from '@/components/base/ToastNotification.vue';
import Header6 from '@/components/base/typography/Header6.vue';
import SmallBodyText from '@/components/base/typography/SmallBodyText.vue';
import NavLink from '@/components/layout/footer/NavLink.vue';
import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useCallback } from '@/composables/useCallback';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';
import { useCustomer } from '@/stores/customer';

interface Link {
  title: string;
  url: string;
}

const { errorMessages, validatorFailed } = useForm();

const subscribed = ref(false);
const isEmailInputFocused = ref(false);
const emailInput = ref(useCustomer().customer?.email ?? '');
const firstNameInput = ref(useCustomer().customer?.firstName ?? '');
const notificationMessage = ref<string>();

const displayFirstNameInput = computed(() => emailInput.value || isEmailInputFocused.value);

const rootElement = ref<HTMLElement>();
useRouterLinks(rootElement, useRouter());

const formInputAttributes: Record<'email' | 'name', InputAttributes> = {
  email: {
    autocomplete: 'email',
    name: 'email',
    placeholder: 'Your Email',
    type: 'email',
  },
  name: {
    name: 'name',
    placeholder: 'Your Name (optional)',
    type: 'text',
  },
};

const rules: Record<'email', FlexibleMessageValidator<string>> = {
  email: (value) => {
    if (!required(value)) return errorMessages.email.required;
    if (!email(value)) return errorMessages.email.email;
    return true;
  },
};

const onSubmit = useCallback(async () => {
  try {
    await subscribe(emailInput.value, firstNameInput.value);
    subscribed.value = true;
    notificationMessage.value =
      'Thank you for subscribing! Stay tuned for exciting updates and exclusive content!';
  } catch (error) {
    notificationMessage.value = 'Oops... Something went wrong. Please try again.';
  }
});

const navLinks: Link[] = [
  { title: 'About Us', url: '/about-us' },
  { title: 'Shipping', url: '/shipping' },
  {
    title: 'Help',
    url: useFeatureFlags().flags.gladly ? '/help-center' : 'https://help.nuts.com/',
  },
  { title: 'Privacy Policy', url: '/privacy' },
  { title: 'CA Privacy Notice', url: '/california-privacy-notice' },
  { title: 'Accessibility', url: '/accessibility.html' },
];

const legalLinks: Link[] = [
  { title: 'T & C', url: '/terms-and-conditions' },
  { title: 'Do Not Sell My Personal Information', url: '/ccpa-opt-out' },
  {
    title: 'Limit the Use of My Sensitive Personal Information',
    url: '/opt-out-of-use-sharing-of-sensitive-information-form',
  },
];
</script>

<template>
  <footer class="py-8 bg-nuts-neutral-950" data-test="footer">
    <Container>
      <div
        class="flex flex-col items-center gap-6 py-8 mx-auto md:gap-10 md:justify-between md:flex-row xl:max-w-screen-2xl lg:gap-0"
        ref="rootElement"
      >
        <a href="/" data-test="footer-logo">
          <picture>
            <source
              media="(min-width: 1024px)"
              height="72"
              itemprop="logo"
              loading="lazy"
              width="72"
              srcset="@/assets/layout/nuts-stamp-logo.svg"
            />
            <img
              alt="Nuts.com"
              height="64"
              itemprop="logo"
              loading="lazy"
              width="64"
              src="@/assets/layout/nuts-stamp-logo.svg"
            />
          </picture>
        </a>

        <div
          class="flex flex-col gap-3 mt-6 md:mt-0 md:items-center 2xl:max-w-none"
          :class="displayFirstNameInput ? 'md:flex-col lg:flex-row' : 'md:flex-row'"
        >
          <Header6
            class="text-white lg:max-w-[212px] w-72"
            :class="displayFirstNameInput ? 'w-full text-center' : 'md:max-w-[200px] text-start'"
          >
            Subscribe to our newsletter and get exclusive offers!
          </Header6>
          <Form
            v-if="!subscribed"
            v-slot="{ meta: formEmailMeta }"
            class="flex flex-col gap-5 md:gap-3 md:flex-row"
            @submit="onSubmit.execute()"
          >
            <FormInput
              v-model="emailInput"
              aria-label="Email"
              class="w-full"
              :inputAttributes="formInputAttributes.email"
              :validator="rules.email"
              showPlaceholder
              showLabel
              theme="dark"
              @blur="isEmailInputFocused = false"
              @focus="isEmailInputFocused = true"
            />
            <transition name="fade">
              <FormInput
                v-if="displayFirstNameInput"
                v-model="firstNameInput"
                aria-label="Firt name"
                class="w-full"
                :inputAttributes="formInputAttributes.name"
                showLabel
                showPlaceholder
              />
            </transition>
            <ThemedButton
              type="submit"
              size="default"
              theme="dark-yellow"
              class="w-full md:w-min"
              :disabled="(formEmailMeta.touched && validatorFailed(formEmailMeta)) ?? false"
              :isLoading="onSubmit.isPending"
            >
              Subscribe
            </ThemedButton>
          </Form>
        </div>
        <ToastNotification
          v-if="subscribed"
          :show="!!notificationMessage"
          @hide="notificationMessage = ''"
        >
          <SmallBodyText>{{ notificationMessage }}</SmallBodyText>
        </ToastNotification>
      </div>
      <hr class="h-px m-0 bg-neutral-300" />

      <div class="py-4" data-test="footer-bottom-shelf-container">
        <ul class="flex gap-6 place-content-center">
          <li v-for="navLink in navLinks" :key="navLink.title">
            <NavLink :title="navLink.title" :url="navLink.url" />
          </li>
        </ul>
        <ul class="flex gap-6 mt-2 place-content-center">
          <li v-for="legalLink in legalLinks" :key="legalLink.title">
            <NavLink :title="legalLink.title" :url="legalLink.url" />
          </li>
        </ul>
      </div>
    </Container>
  </footer>
</template>
