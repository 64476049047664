import { Customer, DiscountCode } from '@commercetools/platform-sdk';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { fromNutsJson, NutsJson } from '@/api';
import business from '@/api/account/business';
import {
  addAddress,
  CarrierOptions,
  CreditAccount,
  CreditAccountResponse,
  describeCustomer,
  DescribeCustomerResponse,
  DescribeGuestCustomerResponse,
  describeMe,
  getAddresses,
  getCreditAccounts,
  Issue,
  updateAddress,
} from '@/api/customer';
import { CUSTOMER_ORDER_COUNT_COOKIE } from '@/lib/personalization/common';
import { useSession } from '@/stores/session';
import { NutsAddress } from '@/utils/address';
import { isDefined } from '@/utils/isDefined';
import { getCookie } from '@/utils/isomorphic/cookie';
import { reportError } from '@/utils/reportError';

/**
 * Changes with this structure must be kept in sync with the `php-backend`'s `Contact` model method `toWebfront()`
 */
export interface Contact {
  email: string;
  firstName?: string;
  id: number;
  isB2b?: boolean;
  subscribed: boolean;
}

export interface CustomerState {
  addresses: NutsAddress[];
  adminUrl?: string;
  allowSplitShipments?: boolean;
  businessIndustry?: string;
  businessName?: string;
  carrierOptions?: CarrierOptions;
  contact?: Contact;
  creditAccount?: CreditAccount;
  customer?: Customer;
  customerDiscountCodes: DiscountCode[];
  hasBusinessAccount: boolean;
  issues: Issue[];
  notes?: string;
  signedUpAt?: string;
  storeCredit?: CreditAccount;
  subscribed?: boolean;
}

const handleNJ = <T>(promise: Promise<NutsJson<T>>) =>
  fromNutsJson(promise, {
    onMessages: 'default',
  });

export const useCustomer = defineStore('customer', () => {
  const addresses = ref<CustomerState['addresses']>([]);
  const adminUrl = ref<CustomerState['adminUrl']>();
  const allowSplitShipments = ref<CustomerState['allowSplitShipments']>();
  const businessIndustry = ref<CustomerState['businessIndustry']>();
  const businessName = ref<CustomerState['businessName']>();
  const carrierOptions = ref<CustomerState['carrierOptions']>();
  const contact = ref<CustomerState['contact']>();
  const creditAccount = ref<CustomerState['creditAccount']>();
  const customer = ref<CustomerState['customer']>();
  const customerDiscountCodes = ref<CustomerState['customerDiscountCodes']>([]);
  const hasBusinessAccount = ref<CustomerState['hasBusinessAccount']>(false);
  const issues = ref<CustomerState['issues']>([]);
  const notes = ref<CustomerState['notes']>();
  const signedUpAt = ref<CustomerState['signedUpAt']>();
  const storeCredit = ref<CustomerState['storeCredit']>();

  const customerLasershipAllowed = computed(() => carrierOptions.value?.allowLasership ?? true);
  const customerOntracAllowed = computed(() => carrierOptions.value?.allowOntrac ?? true);
  const customerRegionalCarriersAllowed = computed(
    () =>
      (carrierOptions.value?.allowBlueStreak &&
        carrierOptions.value.allowCdl &&
        carrierOptions.value.allowGrandHusky &&
        carrierOptions.value.allowTforce &&
        carrierOptions.value.allowUds) ??
      true,
  );

  const subscribed = computed(() => contact.value?.subscribed);
  const offerNewsletter = computed(() => {
    const existingUnsubscribedUser = !!customer.value && !subscribed.value;
    const existingUnsubscribedContact = !customer.value && subscribed.value === false;
    return existingUnsubscribedUser || existingUnsubscribedContact;
  });

  const setCustomerInfo = (
    customerDescription?: DescribeCustomerResponse | DescribeGuestCustomerResponse,
  ) => {
    if (!customerDescription) businessIndustry.value = undefined;
    addresses.value = customerDescription?.addresses ?? [];
    adminUrl.value = customerDescription?.adminUrl;
    allowSplitShipments.value = customerDescription?.allowSplitShipments;
    carrierOptions.value = customerDescription?.carrierOptions;
    contact.value = customerDescription?.contact;
    creditAccount.value = customerDescription?.creditAccounts?.creditAccount;
    customer.value = customerDescription?.customer;
    customerDiscountCodes.value = customerDescription?.discountCodes ?? [];
    hasBusinessAccount.value = customerDescription?.hasBusinessAccount ?? false;
    issues.value = customerDescription?.issues ?? [];
    notes.value = customerDescription?.notes;
    signedUpAt.value = customerDescription?.signedUpAt;
    storeCredit.value = customerDescription?.creditAccounts?.storeCreditAccount;
  };

  async function loadCustomerDescription(email?: string) {
    try {
      const info = await handleNJ(email ? describeCustomer(email) : describeMe());
      setCustomerInfo(info);
    } catch (error) {
      reportError(error);
      setCustomerInfo();
      throw error;
    }
  }

  return {
    addresses,
    adminUrl,
    allowSplitShipments,
    businessIndustry,
    businessName,
    carrierOptions,
    contact,
    creditAccount,
    customer,
    customerDiscountCodes,
    customerLasershipAllowed,
    customerOntracAllowed,
    customerRegionalCarriersAllowed,
    hasBusinessAccount,
    issues,
    loadCustomerInfo: loadCustomerDescription,
    loadGuestContactInfo: (email: string) => loadCustomerDescription(email),
    notes,
    offerNewsletter,
    setCustomerInfo,
    signedUpAt,
    storeCredit,
    subscribed,

    async addAddress(address: NutsAddress, customerEmail?: string): Promise<NutsAddress> {
      const { address: savedAddress, addresses: customerAddresses } = await handleNJ(
        addAddress(address, customerEmail),
      );
      addresses.value = customerAddresses;
      return savedAddress;
    },

    async convertToBusinessAccount() {
      await business.createBusinessUnit({ source: 'User Converted' });
      hasBusinessAccount.value = true;
    },

    async getAvailableUniqueAddresses(email?: string) {
      const { addresses: customerAddresses = [] } = await handleNJ(getAddresses(email));
      addresses.value = customerAddresses;
    },

    async loadCreditAccounts() {
      let response: CreditAccountResponse | undefined;
      if (customer.value) {
        try {
          response = await handleNJ(getCreditAccounts(customer.value.id));
        } catch (error) {
          console.error(error);
        }
      }
      creditAccount.value = response?.creditAccount;
      storeCredit.value = response?.storeCreditAccount;
    },

    sendIdentifyEvent() {
      const sessionStore = useSession();
      try {
        if (contact.value && !sessionStore.permissions.checkOutAsCustomer) {
          let company;
          if (businessIndustry.value) {
            company = {
              industry: businessIndustry.value,
              name: businessName.value,
            };
          }

          const orderCount = getCookie(CUSTOMER_ORDER_COUNT_COOKIE);
          const orderCountNumber = isDefined(orderCount) ? Number(orderCount) : undefined;

          window.rudderanalytics?.identify(String(contact.value.id), {
            company,
            contact_id: contact.value.id,
            contact_is_b2b: contact.value.isB2b,
            createdAt: sessionStore.userCreatedAt,
            customer_id: customer.value?.id,
            email: sessionStore.email || contact.value.email,
            firstName: sessionStore.userFirstName || contact.value.firstName,
            order_count: orderCountNumber,
          });
        } else {
          window.rudderanalytics?.identify('');
        }
      } catch (error) {
        console.error(error);
      }
    },

    setBusinessAccountInfo(businessAccountInfo: {
      hasBusinessAccount: boolean;
      industry?: string;
    }) {
      businessIndustry.value = businessAccountInfo.industry;
      hasBusinessAccount.value = businessAccountInfo.hasBusinessAccount;
    },

    setBusinessUnitIndustry(industry: string) {
      businessIndustry.value = industry;
    },

    async updateAddress(address: NutsAddress, customerEmail?: string) {
      const { address: savedAddress, addresses: customerAddresses } = await handleNJ(
        updateAddress(address, customerEmail),
      );
      addresses.value = customerAddresses;
      return savedAddress;
    },
  };
});
